<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :inputs="inputs" :selects="selects" :timeRange="timeRange" :showTypeSelect="true" @search="search"
          @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0">导出</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading"
          :rowKey="(tableData) => tableData.rowKey" :pagination="pagination" @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { accountRecordList,userLog } from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "账户管理",
        },
        {
          key: 1,
          title: "账户明细",
        },
      ],
      inputs: [
        {
          placeholder: "姓名",
          model: "realname",
          width: 100,
        },
        {
          placeholder: "手机号码",
          model: "mobile",
          width: 100,
        },
      ],
      selects: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
      ],
      timeRange: {
        show: true,
        placeholderStart: "开始时间",
        placeholderEnd: "结束时间",
        rangeLimit: true
      },
      queryParam: {
        page: 1,
        office_id: "",
        post_id: "",
        realname: "",
        mobile: "",
        begin_date: "",
        end_date: "",
        transaction_type: "",
        transaction_type_child: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      officesData: [],
      postsData: [],
      typeData: [],
      dataForExport: [],
      loading: false,
      columns: [
        {
          title: "姓名",
          key: "user_realname",
          dataIndex: "user_realname",
        },
        {
          title: "手机",
          key: "user_mobile",
          dataIndex: "user_mobile",
        },
        {
          title: "发生额",
          key: "money",
          dataIndex: "money",
        },
        {
          title: "余额",
          key: "acco_amount",
          dataIndex: "acco_amount",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "类型",
          key: "type_title",
          dataIndex: "type_title",
        },
        {
          title: "用餐时段",
          key: "meal_name",
          dataIndex: "meal_name",
        },
        {
          title: "消费类型",
          key: "type_name",
          dataIndex: "type_name",
        },
        {
          title: "操作人",
          key: "operation",
          dataIndex: "operation",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
        },
        // {
        //   title: "操作",
        //   key: "action",
        //   scopedSlots: { customRender: "action" },
        // },
      ], // 表格的列配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel
    export2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = [
          "姓名",
          "手机",
          "科室/部门",
          "职务",
          "发生额",
          "余额",
          "类型",
          "用餐时段",
          "消费类型",
          "备注",
          "创建日期",
          "订单号",
          "就餐日期"
        ]; // 导出的表头名信息
        const filterVal = [
          "user_realname",
          "user_mobile",
          "user_office",
          "user_post",
          "money",
          "acco_amount",
          "type_title",
          "meal_name",
          "type_name",
          "remark",
          "created_at",
          "order_number",
          "repast_date"
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "会员账户列表"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },
    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 刷新
    search (val) {
      // console.log(val);
      this.queryParam = {
        page: 1,
        office_id: val.select.office_id ? val.select.office_id : "",
        post_id: val.select.post_id ? val.select.post_id : "",
        realname: val.input.realname ? val.input.realname : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
        transaction_type: val.selectWithSecond.transaction_type
          ? val.selectWithSecond.transaction_type
          : "",
        transaction_type_child: val.selectWithSecond.transaction_type_child
          ? val.selectWithSecond.transaction_type_child
          : "",
      };
      // console.log(this.queryParam);
      this.pagination.current = 1;
      this.getTableData();

      //搜索请求后要清除原先的selects数据不然会造成重复添加selects数据导致key重复报错
      this.selects = [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
      ];
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取分页的数据用于展示
      this.queryParam.no_paging = 0;
      accountRecordList(this.queryParam).then((res) => {
        const pagination = { ...this.pagination };
        pagination.total = res.data.list.total;
        this.pagination = pagination;
        // console.log(this.pagination);
        if (res.data.list.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        let rowKey = 1,
          formatArr = res.data.list.data;
        this.tableData = res.data.list.data;
        for (let i = 0; i < formatArr.length; i++) {
          //因为接口返回的数组对象没有id所以给每个对象增加一个自增的rowKey作为渲染table的必要属性rowkey
          formatArr[i].rowKey = rowKey++;
        }
        this.tableData = formatArr;

         console.log(this.tableData)
        this.officesData = res.data.office;
        this.postsData = res.data.post;

        for (let key in res.data.type) {
          this.typeData.push({
            key,
            value: res.data.type[key],
          });
        }
        // console.log(this.typeData)
        for (let i = 0; i < this.officesData.length; i++) {
          this.selects[0].options.push({
            key: this.officesData[i].id,
            value: this.officesData[i].title,
          });
        }
        for (let i = 0; i < this.postsData.length; i++) {
          this.selects[1].options.push({
            key: this.postsData[i].id,
            value: this.postsData[i].title,
          });
        }
        this.loading = false;
      });

      //请求不分页的数据
      this.queryParam.no_paging = 1;
      accountRecordList(this.queryParam).then((res) => {
        // console.log(res);
        this.dataForExport = res.data.list.map(item => {
          return item = {
            ...item,
            order_number: item.order_number ? item.order_number : '无',
            repast_date: item.repast_date ? item.repast_date : '无'
          }
          
        });
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            begin_date:this.queryParam.begin_date,
            end_date:this.queryParam.end_date,
            name:this.queryParam.realname,
            mobile:this.queryParam.mobile,
            office:this.queryParam.office_id ? this.selects[0].options.find(item => item.key === this.queryParam.office_id).value : '',
            post:this.queryParam.post_id ? this.selects[1].options.find(item => item.key == this.queryParam.post_id).value : '',
            type:this.queryParam.transaction_type == 1 ? '现金充值' : this.queryParam.transaction_type == 2 ? '批量充值' : this.queryParam.transaction_type == 3 ? '个别调整' : this.queryParam.transaction_type == 4 ? '批量调整' : this.queryParam.transaction_type == 5 ? '临时用餐' : this.queryParam.transaction_type == 6 ? '商城消费' : this.queryParam.transaction_type == 7 ? '余额消费' : this.queryParam.transaction_type == 8 ? '取消报餐' : this.queryParam.transaction_type == 9 ? '差错充值' : this.queryParam.transaction_type == 10 ? '差错调整' : this.queryParam.transaction_type == 11 ? '微信退款' : this.queryParam.transaction_type == 12 ? '微信支付' : this.queryParam.transaction_type == 13 ? '现场消费' : '全部',
            total:this.dataForExport.length
          };
      if(this.queryParam.transaction_type == 1){
        param.secondType = this.queryParam.transaction_type_child == 1 ? '现金' : this.queryParam.transaction_type_child == 2 ? '微信' : this.queryParam.transaction_type_child == 3 ? '支付宝' : this.queryParam.transaction_type_child == 4 ? '银行卡' : this.queryParam.transaction_type_child == 5 ? '其他' : '全部'    
      }else if(this.queryParam.transaction_type == 6){
        param.secondType = this.queryParam.transaction_type_child == 3 ? '扫码消费' : this.queryParam.transaction_type_child == 4 ? '小卖部' : '全部' 
      }
      
      userLog(moduleId,param).then(res => {
        // console.log('导出' + res)
      }).catch(err => {
        console.log('userLog接口请求错误：')
        console.log(err)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>